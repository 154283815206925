<template>
  <div class="widgetContainer filter">
    <div class="navigationHeader">
      <div class="navigationHeader__inner">
        <p class="title">
          {{ $t('filter') }}
        </p>
        <span class="el-icon-close" @click="drawerClose" />
      </div>
    </div>

    <div class="widgetContainer__body">
      <el-form class="wise-form" ref="cardDetail" :model="filterData">
        <el-collapse v-model="activeNames">
          <el-collapse-item name="1">
            <template slot="title">
              <p class="wise-form__header">
                {{ $t('filterHeader_type') }}
              </p>
              <i
                :class="
                  'header-icon ' +
                    (activeNames.includes('1') ? 'el-icon-minus' : 'el-icon-plus')
                " />
            </template>

            <div class="filter__transaction-type">
              <el-radio-group v-model="filterData.transactionType.value">
                <el-radio class="brand" label="credit">
                  {{ $t('credits') }}
                </el-radio>
                <el-radio class="brand" label="debit">
                  {{ $t('debits') }}
                </el-radio>
              </el-radio-group>
            </div>
          </el-collapse-item>

          <el-collapse-item name="2">
            <template slot="title">
              <p class="wise-form__header">
                {{ $t('filterHeader_period') }}
              </p>
              <i
                :class="
                  'header-icon ' +
                    (activeNames.includes('2') ? 'el-icon-minus' : 'el-icon-plus')
                " />
            </template>

            <div class="filter__time-period">
              <el-radio-group class="brand" v-model="filterData.timePeriod.value">
                <el-radio label="this_week">
                  {{ $t('this_week') }}
                </el-radio>
                <el-radio label="this_month">
                  {{ $t('this_month') }}
                </el-radio>
                <el-radio label="last_month" v-if="showLastMonthLabel">
                  {{ $t('last_month') }}
                </el-radio>
                <el-radio label="custom">
                  {{ $t('custom') }}
                </el-radio>
              </el-radio-group>

              <div
                class="filter__time"
                v-if="filterData.timePeriod.value == 'custom'">
                <div>
                  <el-form-item
                    class="is-no-asterisk"
                    prop="startDate"
                    :label="$t('from')">
                    <el-date-picker
                      format="MM-dd-yyyy"
                      v-model="filterData.timePeriod.startDate"
                      value-format="yyyy-MM-dd"
                      :picker-options="datePickerOptions"
                      prefix-icon="false"
                      type="date" />
                  </el-form-item>
                </div>

                <div>
                  <el-form-item
                    class="is-no-asterisk"
                    prop="endDate"
                    :label="$t('to')">
                    <el-date-picker
                      format="MM-dd-yyyy"
                      v-model="filterData.timePeriod.endDate"
                      value-format="yyyy-MM-dd"
                      prefix-icon="false"
                      :picker-options="datePickerOptions"
                      type="date" />
                  </el-form-item>
                </div>
              </div>
            </div>
          </el-collapse-item>

          <el-collapse-item name="3">
            <template slot="title">
              <p class="wise-form__header">
                {{ $t('filterHeader_amount') }}
              </p>
              <i
                :class="
                  'header-icon ' +
                    (activeNames.includes('3') ? 'el-icon-minus' : 'el-icon-plus')
                " />
            </template>

            <div class="filter__amount">
              <div>
                <el-form-item
                  class="is-no-asterisk"
                  prop="limitInterval"
                  :label="$t('from')">
                  <Amount
                    :precision="2"
                    v-model="filterData.amount.from"
                    :masked="true" />
                </el-form-item>
                <el-form-item
                  class="is-no-asterisk"
                  prop="limitInterval"
                  :label="$t('to')">
                  <Amount
                    :precision="2"
                    v-model="filterData.amount.to"
                    :masked="true" />
                </el-form-item>
              </div>
              <div />
            </div>
          </el-collapse-item>

          <el-collapse-item
            name="4"
            v-if="getFilters.cardId == null && getFilters.contactId == null">
            <template slot="title">
              <p class="wise-form__header">
                {{ $t('filterHeader_card') }}
              </p>
              <i
                :class="
                  'header-icon ' +
                    (activeNames.includes('4') ? 'el-icon-minus' : 'el-icon-plus')
                " />
            </template>

            <div class="filter__cards">
              <el-select
                :disabled="!getCards.data.length"
                :placeholder="setCardSeletionPlaceholder"
                v-model="filterData.cards.value">
                <el-option
                  v-for="item in getCards.data"
                  :key="item.id"
                  :label="item.label"
                  :value="item.id" />
              </el-select>
            </div>
          </el-collapse-item>
        </el-collapse>
      </el-form>
    </div>
    <div class="el-drawer__footer">
      <el-button type="primary" class="el-button__brand brand width-100" @click="next">
        {{ $t('filter_apply') }}
      </el-button>
    </div>
  </div>
</template>

<script>
import Amount from '@/components/Amount';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { startOfWeek, endOfWeek, startOfMonth, endOfMonth, max } from 'date-fns';
import { formatDate } from '@/utils/general';

export default {
  name: 'Filter',
  components: {
    Amount
  },
  computed: {
    ...mapGetters('transaction', ['getFilters']),
    ...mapGetters('card', ['getCards']),
    ...mapGetters('account', ['getSelectedAccount']),
    setCardSeletionPlaceholder() {
      return this.getCards.data.length ? this.$t('filterHeader_card') : this.$t('no_cards');
    },
    accountCrationDate() {
      const createdAt = this.getSelectedAccount.createdAt;
      return new Date(createdAt).setHours(0,0,0,0);
    },
    minDate() {
      return new Date().setHours(0,0,0,0);
    },
    showLastMonthLabel() {
      const today = new Date();
      const createdMonth = new Date(this.accountCrationDate).getMonth();
      const createdYear = new Date(this.accountCrationDate).getFullYear();
      const currentMonth = today.getMonth();
      const currenYear = today.getFullYear();
      return (currenYear !== createdYear || currentMonth !== createdMonth);
    }
  },
  data() {
    return {
      activeNames: ['1', '2', '3', '4'],
      filterData: {
        transactionType: {
          value: null
        },
        timePeriod: {
          value: null,
          startDate: null,
          endDate: null
        },
        amount: {
          from: '0.00',
          to: '0.00'
        },
        cards: {
          value: null
        }
      },
      datePickerOptions: {
        disabledDate: (date) => {
          return date > this.minDate || date < this.accountCrationDate;
        }
      }
    };
  },
  created() {
    if (
      this.getCards &&
      this.getCards.data.length === 0 &&
      this.getSelectedAccount &&
      this.getFilters.cardId == null &&
      this.getFilters.contactId == null
    ) {
      const loader = this.showLoader();
      this.listAllCardByAccount({accountId: this.getSelectedAccount.id})
        .catch((e) => {
          console.log(e);
          this.apiErrorMessage(e);
        })
        .finally(() => {
          loader.close();
          this.isLoading = false;
        });
    }
    this.filterData = {
      transactionType: {
        value: this.getFilters.txnType
      },
      timePeriod: {
        value: this.getFilters.dateRangeValue,
        startDate: this.getFilters.startDate,
        endDate: this.getFilters.endDate
      },
      amount: {
        from: this.getFilters.minAmount || '0.00',
        to: this.getFilters.maxAmount || '0.00'
      },
      cards: {
        value: this.getFilters.cardIdFromFilter
      }
    };
  },
  methods: {
    ...mapMutations('transaction', ['updateFilters']),
    ...mapActions('card', ['listAllCardByAccount']),
    next() {
      let { startDate, endDate } = this.filterData.timePeriod;
      const today = new Date();
      if (this.filterData.timePeriod.value === 'this_week') {
        startDate = formatDate(startOfWeek(today), 'yyyy-MM-dd');
        endDate = formatDate(endOfWeek(today), 'yyyy-MM-dd');
      } else if (this.filterData.timePeriod.value === 'this_month') {
        startDate = formatDate(startOfMonth(today), 'yyyy-MM-dd');
        endDate = formatDate(endOfMonth(today), 'yyyy-MM-dd');
      } else if (this.filterData.timePeriod.value === 'last_month') {
        const lastday = new Date(today.getFullYear(), today.getMonth(), 0);
        startDate = formatDate(startOfMonth(lastday), 'yyyy-MM-dd');
        endDate = formatDate(endOfMonth(lastday), 'yyyy-MM-dd');
      }
      startDate = formatDate(max([this.accountCrationDate, new Date(startDate)]), 'yyyy-MM-dd');

      const filters = {
        ...this.getFilters,
        dateRangeValue: this.filterData.timePeriod.value,
        startDate,
        endDate,
        minAmount: this.filterData.amount.from,
        maxAmount: this.filterData.amount.to,
        txnType: this.filterData.transactionType.value,
        transferType: null,
        subType: null,
        cardIdFromFilter: this.filterData.cards.value,
        cardLabelFromFilter: this.filterData.cards.value
          ? this.getCards.data.find((c) => c.id === this.filterData.cards.value)
            .label
          : null
      };
      this.updateFilters(filters);
      setTimeout(() => {
        this.$emit('apply');
        this.drawerClose();
      }, 0);
    },
    clearAll() {
      this.filterData = {
        transactionType: {
          value: null
        },
        timePeriod: {
          value: null,
          startDate: null,
          endDate: null
        },
        amount: {
          from: '0.00',
          to: '0.00'
        },
        cards: {
          value: null
        }
      };
      const filters = {
        ...this.getFilters,
        dateRangeValue: null,
        startDate: null,
        endDate: null,
        minAmount: '0.00',
        maxAmount: '0.00',
        txnType: null,
        transferType: null,
        subType: null,
        cardIdFromFilter: null,
        cardLabelFromFilter: null
      };
      this.updateFilters(filters);
      setTimeout(() => {
        this.$emit('apply');
      }, 0);
    },
    cancel() {
      this.drawerClose();
    }
  }
};
</script>

<style lang="scss">
.widgetContainer {
  &.filter {
    .widgetContainer__body {
        background-color: #fff;
        padding-bottom: 50px;
      }
  }
}
.filter {
  .el-collapse-item {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 24px;
  }
  .el-collapse-item__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    outline: none;
    padding-bottom: 24px;
    border-bottom: 1px solid transparent;

    i.el-collapse-item__arrow {
      display: none;
    }
  }
  .loginHeader {
    background-color: #fff;
    border-bottom: 1px solid #eaeaea;
    min-height: 90px;
  }
  .wise-form__header {
    padding-bottom: 0;
  }

  .loginHeader__footer {
    .cancel {
      display: inline-block;
    }
    .description {
      left: unset;
      transform: unset;
      display: inline-block;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0em;
      text-align: left;
    }
    .reset {
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0em;
      text-align: center;
      color: #767676;
    }
  }

  &__transaction-type,
  &__time-period {
    .el-radio-group {
      display: flex;
      flex-direction: column;

      .el-radio {
        margin-bottom: 20px;
      }
    }
  }

  &__time {
    display: flex;

    > div {
      flex: 1;

      &:first-of-type {
        padding-right: 40px;
      }
    }

    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
      width: auto;
    }

    .el-input__inner {
      padding: 0 15px;
    }
  }

  &__amount {
    > div {
      display: flex;

      .el-form-item {
        &:first-of-type {
          padding-right: 40px;
        }
      }
    }
  }

  &__cards {
    .el-select {
      width: 100%;

      input {
        &::placeholder {
          color: #3c3c43;
        }
      }
    }
  }
}
</style>
